import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import { Location, Phone, Clock, ViewLocked, Home } from '@agendapro/emerald-icons';

import { Paragraph, WhatsAppLink } from '@/UI';
import * as St from './Map.styles';
import { MapTypes } from './Map.types';
import { LocationTimes } from '@/entries/Locations/components/LocationTimes';
import { CopyClipboard } from '@/components/CopyClipboard';
import { useServiceProviders } from '@/services/serviceProviders';
import usePageContext from '@/hooks/usePageContext';
import ReviewsInfo from '../CompanyInfo/ReviewsInfo';

export const Map: FC<MapTypes> = (props) => {
  const {
    image,
    address,
    phone,
    times,
    collapsed,
    outcall,
    hasDescription,
    hasBanner,
    viewType,
    locationId,
    whatsappUrl,
    whatsappActive,
    secondaryPhone,
  } = props;
  const { t } = useTranslation();
  const theme = useTheme();
  const { data: providersByLocation } = useServiceProviders(locationId);
  const [showTimes, setShowTimes] = useState(false);
  const { currentLocation } = usePageContext();

  useEffect(() => {
    if (collapsed) {
      setShowTimes(false);
    }
  }, [collapsed]);

  const handlePhoneNumber = () => {
    if (phone && secondaryPhone) {
      return `${phone} - ${secondaryPhone}`;
    }

    if (secondaryPhone) {
      return secondaryPhone;
    }

    if (phone) {
      return phone;
    }

    return null;
  };

  return (
    <St.Wrapper data-testid="mapContainer" hasDescription={hasDescription} hasBanner={hasBanner} viewType={viewType}>
      <a
        href={`https://www.google.com/maps/search/?api=1&query=${currentLocation?.address}`}
        target="_blank"
        rel="noreferrer"
      >
        <St.WrapperImage
          collapsed={collapsed}
          hasBanner={hasBanner}
          hasDescription={hasDescription}
          image={image}
          outcall={outcall}
          viewType={viewType}
        />
      </a>

      <St.WrapperContent hasDescription={hasDescription} viewType={viewType} hasBanner={hasBanner}>
        {outcall && (
          <>
            <St.ItemDetailContainer>
              <Home size={20} color={theme.palette.grays80} className="icon" />
              <Paragraph weight="light">{t('HOME_SERVICE')}</Paragraph>
            </St.ItemDetailContainer>
          </>
        )}
        {address && (
          <>
            <St.ItemDetailContainer>
              <Location size={20} color={theme.palette.grays80} className="icon" />
              <St.TextBox>
                <a
                  href={`https://www.google.com/maps/search/?api=1&query=${currentLocation?.address}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Paragraph weight="light" size="paragraph">
                    {address}
                  </Paragraph>
                </a>
              </St.TextBox>
            </St.ItemDetailContainer>
          </>
        )}
        {(phone || secondaryPhone) && (
          <St.ItemDetailContainer>
            <Phone size={20} color={theme.palette.grays80} className="icon" />
            <CopyClipboard text={phone}>
              <St.StyledPhone weight="light">{handlePhoneNumber()}</St.StyledPhone>
            </CopyClipboard>
          </St.ItemDetailContainer>
        )}
        {whatsappActive && whatsappUrl && (
          <WhatsAppLink
            whatsAppUrl={whatsappUrl}
            text={providersByLocation?.length === 1 ? t('CONTACT_ME_WHATSAPP') : ''}
          />
        )}
        {!!times.length && (
          <St.WrapperTime>
            <Clock size={20} color={theme.palette.grays80} className="icon" />
            <div>
              {showTimes && <LocationTimes className="TimeContainer" locationsTimes={times} />}

              <Paragraph
                weight="light"
                onClick={() => {
                  setShowTimes(!showTimes);
                }}
                className="showTimes "
                data-testid="showTimes"
              >
                {showTimes ? (
                  <span data-testid="closeTimes">
                    <ViewLocked size={20} color={theme.palette.grays80} className="icon" />
                    {t('SEE_LESS_BTN')}
                  </span>
                ) : (
                  t('SEE_SCHEDULE')
                )}
              </Paragraph>
            </div>
          </St.WrapperTime>
        )}
        <ReviewsInfo showInXLarge />
      </St.WrapperContent>
    </St.Wrapper>
  );
};
